import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import "../Styles/Connect.css";
import Swal from 'sweetalert';

const Connect = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    street: '',
    city: '',
    postCode: '',
    phoneContact: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    from_name: '',
    street: '',
    city: '',
    postCode: '',
    phoneContact: '',
    email: '',
    message: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.from_name.trim()) {
      errors.from_name = 'Contact Name is required';
      isValid = false;
    }

    if (!formData.street.trim()) {
      errors.street = 'Street is required';
      isValid = false;
    }

    if (!formData.phoneContact.trim()) {
      errors.phoneContact = 'Contact Phone is required';
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = 'E-mail is required';
      isValid = false;
    }

    if (!formData.city.trim()) {
      errors.city = 'City is required';
      isValid = false;
    }

    if (!formData.postCode.trim()) {
      errors.postCode = 'PostCode is required';
      isValid = false;
    }

    if (!formData.message.trim()) {
      errors.message = 'Message is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const form = useRef();

  const showAlert = () => {
    Swal("Message Send!", "Your message was successfully send", "success");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs.sendForm('service_vcd0qqf', 'template_qn3p279', form.current, 'h_mAiTvXCNvxRujHx')
        .then((result) => {
          showAlert();
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
    }
  };

  return (
    <div className="container-fluid p-0 background-form mt-5">
      <div className="row w-100 justify-content-center m-0">
        <div className="col-10 card-connect my-5">
          <div className="row w-100 m-0 justify-content-center">
            <div className="col-12 col-md-12 col-lg-6 p-0">
              <form className="p-3" ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <h6 className="title-form text-center text-md-start">Get in <span style={{ color: "#B1904C" }}>touch</span></h6>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <p className="subtitle-form text-md-start text-center">We excel fostering leadership skills, instructional design and business strategy. Blue Hawk strategies is your partner in flight.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <input type="text" className={`form-control ${formErrors.from_name && 'is-invalid'}`} name="from_name" id="from_name" placeholder="Contact Name" value={formData.from_name}
                      onChange={handleInputChange} />
                    {formErrors.from_name && <div className="invalid-feedback">{formErrors.from_name}</div>}
                  </div>
                  <div className="form-group col-md-12">
                    <input type="text" className={`form-control ${formErrors.street && 'is-invalid'}`} name="street" id="street" placeholder="Street" value={formData.street}
                      onChange={handleInputChange} />
                    {formErrors.street && <div className="invalid-feedback">{formErrors.street}</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <input type="text" className={`form-control ${formErrors.city && 'is-invalid'}`} name="city" id="city" placeholder="City" value={formData.city}
                      onChange={handleInputChange} />
                    {formErrors.city && <div className="invalid-feedback">{formErrors.city}</div>}
                  </div>
                  <div className="col-6">
                    <input type="text" className={`form-control ${formErrors.postCode && 'is-invalid'}`} name="postCode" id="postCode" placeholder="PostCode" value={formData.postCode}
                      onChange={handleInputChange} />
                    {formErrors.postCode && <div className="invalid-feedback">{formErrors.postCode}</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <input type="text" className={`form-control ${formErrors.phoneContact && 'is-invalid'}`} name="phoneContact" id="phoneContact" placeholder="Contact Phone" value={formData.phoneContact}
                      onChange={handleInputChange} />
                    {formErrors.phoneContact && <div className="invalid-feedback">{formErrors.phoneContact}</div>}
                  </div>
                  <div className="col-md-12">
                    <input type="email" className={`form-control ${formErrors.email && 'is-invalid'}`} name="email" id="email" placeholder="E-mail" value={formData.email}
                      onChange={handleInputChange} />
                    {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                  </div>
                  <div className="col-md-12">
                    <input type="text" className={`form-control ${formErrors.message && 'is-invalid'}`} name="message" id="message" placeholder="Let’s talk about your idea" value={formData.message}
                      onChange={handleInputChange} />
                    {formErrors.message && <div className="invalid-feedback">{formErrors.message}</div>}
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10 my-3">
                    <button type="submit" className="btn-connect w-100 py-2" >SUBMIT</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="row w-100 m-0 justify-content-evenly my-3 d-block d-lg-none">
              <div className="col-10 col-md-10 col-lg-4">
                <div className="row w-100">
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21147&authkey=%21AKiD9ioBT2U7SSQ&width=512&height=512" className="img-fluid" style={{ maxHeight: "50px" }} alt="phone" />
                  </div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="title-info">Phone</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="info-Connect col-12"><a href="tel:407-512-8550" style={{ color: "#013B8D" }}>407-512-8550</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-10 col-md-10 col-lg-4">
                <div className="row w-100">
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21148&authkey=%21AEwj06kZQ8cGrGU&width=256&height=256" className="img-fluid" style={{ maxHeight: "50px" }} alt="email" />
                  </div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="title-info">E-MAIL</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="info-Connect col-12"><a href="mailto: terry@bluehawkstrategies.com" style={{ color: "#013B8D" }}>terry@bluehawkstrategies.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-10 col-md-10 col-lg-4">
                <div className="row w-100">
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21149&authkey=%21AEWumO_SAAGsD-8&width=256&height=256" className="img-fluid" style={{ maxHeight: "50px" }} alt="consulting" />
                  </div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="title-info">CONSULTATION</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="info-Connect col-12"><a href="https://calendly.com/terry-blue/30min" style={{ color: "#013B8D" }}>https://calendly.com/terry-blue/30min</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-10 col-md-10 col-lg-6 py-3 px-0 d-flex align-items-center justify-content-center">
              <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21116&authkey=%21AATNJWHl8zY4WzI&width=1536&height=2048" className="img-fluid h-100" alt="form connect" style={{ borderRadius: "50px", maxHeight: "650px" }} />
            </div>
          </div>
          <div className="row w-100 m-0 justify-content-evenly my-3 hide-mobile">
            <div className="col-10 col-md-10 col-lg-4">
              <div className="row w-100">
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21147&authkey=%21AKiD9ioBT2U7SSQ&width=512&height=512" className="img-fluid" style={{ maxHeight: "50px" }} alt="phone" />
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="title-info">Phone</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className="info-Connect col-12"><a href="tel:407-512-8550" style={{ color: "#013B8D" }}>407-512-8550</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-10 col-md-10 col-lg-4">
              <div className="row w-100">
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21148&authkey=%21AEwj06kZQ8cGrGU&width=256&height=256" className="img-fluid" style={{ maxHeight: "50px" }} alt="email" />
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="title-info">E-MAIL</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className="info-Connect col-12"><a href="mailto: terry@bluehawkstrategies.com" style={{ color: "#013B8D" }}>terry@bluehawkstrategies.com</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-10 col-md-10 col-lg-4">
              <div className="row w-100">
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21149&authkey=%21AEWumO_SAAGsD-8&width=256&height=256" className="img-fluid" style={{ maxHeight: "50px" }} alt="consulting" />
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="title-info">CONSULTATION</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className="info-Connect col-12"><a href="https://calendly.com/terry-blue/30min" style={{ color: "#013B8D" }}>https://calendly.com/terry-blue/30min</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Connect;
