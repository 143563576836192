import React from "react";
import '../Styles/Footer.css'

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid pt-5 pb-3 w-100">
        <div className="row m-0 justify-content-center">
          <div className="col-12 col-md-12 col-lg-12 my-4" style={{ textAlign: "center" }}>
            <h2 className="MainTitle-footer">Let's Connect</h2>
          </div>
        </div>
        <div className="row m-0 justify-content-between" style={{ textAlign: "center" }}>
          <div className="col-4 col-md-4 col-lg-4">
            <p className="title-footer">Company</p>
            <p className="body-footer"><a href="/AboutUs">About</a></p>
            <p className="body-footer"><a href="/Services">Services</a></p>
            <p className="body-footer"><a href="/Connect">Connect</a></p>
          </div>
          <div className="col-4 col-md-4 col-lg-4">
            <p className="title-footer">Social Media</p>
            <div className="row justify-content-center">
              <div className="col-4 col-md-3 col-lg-2 my-2 media-icon" >
                <a href="https://www.facebook.com/profile.php?id=100095471108857">
                  <img className="img-fluid footerImg socialMedia-logo" src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21153&authkey=%21ALtluzXn3qEP0k0&width=64&height=64" alt="facebook logo" />
                </a>
              </div>
              <div className="col-4 col-md-3 col-lg-2 my-2 media-icon">
                <a href="https://instagram.com/bluehawkstrategies?igshid=MzRlODBiNWFlZA==">
                  <img className="img-fluid footerImg socialMedia-logo w-100" src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21152&authkey=%21ALrrUOdLVD5lR6k&width=64&height=64" alt="instagram logo" />
                </a>
              </div>
              <div className="col-4 col-md-3 col-lg-2 my-2 media-icon">
                <a href="https://www.linkedin.com/company/blue-hawk-strategies/">
                  <img className="img-fluid footerImg socialMedia-logo" src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21151&authkey=%21AFscEOrLYzzBato&width=64&height=64" alt="linkedin logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-4 col-md-4 col-lg-4">
            <p className="title-footer col-12">Contact</p>
            <p className="body-footer col-12"><a href="tel:407-512-8550">407-512-8550</a></p>
            <p className="body-footer col-12">Free Consultation</p>
          </div>
        </div>
        <div className="row mx-0 my-2 justify-content-center">
          <div className="col-12 py-0 my-0 text-center">
            <p style={{ color: "#fff" }} className="Copyright">&copy; 2023 Blue Hawk Strategies. All rights reserved. Copyright Policy</p>
            <p style={{ color: "#fff", fontWeight: "500" }} className="Copyright body-footer mb-0">Website Design by <a href="https://printpalace.us/" style={{ color: "#FAFF00", textDecoration: "none" }}>Print Palace</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;