import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services";
import NavigationBar from "./components/Navbar";
import Footer from "./components/Footer";
import Connect from "./Pages/Connect";

function App() {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Connect" element={<Connect />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
