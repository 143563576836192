import React from "react";
import "../Styles/About.css";

const AboutUs = () => {
  return (
    <div className="container-fluid p-0 mt-5">
      <div className="row m-0 w-100 py-0 justify-content-center" style={{ backgroundColor: "#013B8D", minHeight: "310px" }}>
        <div className="col-6 col-md-6 col-lg-5 d-flex flex-column align-items-center px-md-5 justify-content-center" style={{ alignSelf: "center" }}>
          <div className="my-2 text-center text-md-start">
            <span className="titleBannerServices1">Empowering <br /> organizations to rise <br /> to their fullest</span><br />
            <span className="titleBannerServices2">potential.</span>
            <div className="my-3">
              <a href="#OurMission" className="btn-hero">FIND OUT MORE</a>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-5 d-flex flex-column align-items-center justify-content-center py-0">
          <img className="img-fluid w-100 img-banner-about py-5" style={{ minHeight: "300px" }} src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21115&authkey=%21ALD0gJEfqe-GTu8&width=1057&height=1120" alt="banner 1" />
        </div>
      </div>
      <div className="hero banner2 p-5" id="OurMission">
        <div className="row w-100 m-0 justify-content-center">
          <div className="title-banner2 text-center d-md-block d-lg-none">
            <h2 className="MainTitle-banner2">Our Mission</h2>
          </div>
          <div className="col-12 col-md-12 col-lg-6">
            <img className="img-fluid h-100" src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21162&authkey=%21AGcPQL7wfu1vVF8&width=622&height=805" alt="second banner" style={{ width: "100%", objectFit: "contain", maxHeight: "800px" }} />
          </div>
          <div className="col-11 col-md-6 col-lg-6 d-flex flex-column justify-content-center">
            <div className="title-banner2 text-lg-end text-center d-none d-md-none d-lg-block">
              <h2 className="MainTitle-banner2">Our Mission</h2>
            </div>
            <div className="text-banner2 my-3 my-lg-5 h5 text-lg-end text-center" style={{ textAlign: "left", lineHeight: "1.4" }}>
              At Blue Hawk Strategies, our mission is to ignite profound transformations within organizations by redefining how teams learn, collaborate, and excel. We are dedicated to providing tailored training that enhances performance and elevates team dynamics.<br /><br />We believe in driving innovation beyond boundaries, liberating organizations from conventional thinking, and propelling them toward groundbreaking achievements. Our commitment to excellence empowers us to foster an environment where creative solutions flourish in an ever-evolving world.<br /><br />With an unwavering focus on results, we guide organizations to expand market share, enhance operational efficiency, and redefine industries.<br /><br />Our mission is to inspire, guide, and lead organizations toward a future of unlimited possibilities.
            </div>
            <div className="my-3">
              <a href="/Services" className="btn-banner2">LEARN MORE</a>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 w-100 justify-content-center">
        <div className="col-md-6 col-lg-6 p-0 d-none d-md-block">
          <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21118&authkey=%21AMp2Xkq9j8Ic09w&width=997&height=1600" className="img-fluid h-100" alt="About" style={{ maxHeight: "1130px", objectFit: "cover", width: "100%" }} />
        </div>
        <div className="col-10 col-md-6 col-lg-6 text-center d-none d-md-block d-flex flex-column align-items-center justify-content-center p-2">
          <div className="mt-5">
            <h2 className="MainTitle-banner3 pt-4 title-AboutUs">Terry Castillo,<br />Founder and Lead Strategist</h2>
          </div>
          <div className="col-12 col-md-12 col-lg-12 p-4" style={{ fontFamily: "Montserrat, sans-serif" }}>
            <p className="text-aboutUs px-3">Teresa "Terry" Castillo is a results-driven training professional with expertise in adult learning theory, leadership coaching, and business strategy. Terry has established herself as a dynamic leader with a strong track record of creating engaging learning journeys.
              <br /><br />With a proven ability to drive positive change, craft effective strategies, and engage stakeholders at various levels, she makes a lasting impact on corporations, governments, and non-profit organizations.</p>
          </div>
          <div className="col-12 col-md-12 col-lg-12 p-2 my-4">
            <a href="https://calendly.com/terry-blue/30min" className="btn-right-slide-services">MAKE AN APPOINTMENT</a>
          </div>
        </div>
        <div className="col-12 p-0 d-md-none d-block" style={{ position: "relative" }}>
          <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21163&authkey=%21AJxwwStH9BI7gUA&width=1080&height=1440" className="img-fluid w-100 img-opacity-services" alt="background about 3" style={{ maxHeight: "900px", minHeight: "700px", objectFit: "cover" }} />
          <div className="position-absolute top-0 start-0 bottom-0 end-0 text-center d-flex align-items-center flex-column justify-content-center" style={{ zIndex: 1 }}>
            <div className="my-3">
              <h2 className="MainTitle-banner3 title-AboutUs">Terry Castillo,<br />Founder and Lead Strategist</h2>
            </div>
            <div className="p-4" style={{ fontFamily: "Montserrat, sans-serif" }}>
              <p className="text-aboutUs">Teresa "Terry" Castillo is a results-driven training professional with expertise in adult learning theory, leadership coaching, and business strategy. Terry has established herself as a dynamic leader with a strong track record of creating engaging learning journeys.
                <br /><br />With a proven ability to drive positive change, craft effective strategies, and engage stakeholders at various levels, she makes a lasting impact on corporations, governments, and non-profit organizations.</p>
            </div>
            <div className="p-2 my-4">
              <a href="https://calendly.com/terry-blue/30min" className="btn-right-slide-services">MAKE AN APPOINTMENT</a>
            </div>
          </div>
          <div className="overlay" style={{ backgroundColor: "rgba(255, 255, 255, 0.7)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}></div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;
