import React from "react";
import "../Styles/Services.css";

const Services = () => {
  return (
    <div className="container-fluid m-0 p-0 mt-5">
      <div className="bannerServices1">
        <div className="row justify-content-center w-100 mx-0 px-0 py-5">
          <div className="col-6 col-md-5 col-lg-5 d-flex flex-column align-items-center px-md-5" style={{ alignSelf: "center" }}>
            <div className="my-2 text-start">
              <span className="titleBannerServices1">Elevating your<br />team
                to</span><br />
              <span className="titleBannerServices2">excellence.</span>
              <div className="my-3">
                <a href="#Services" className="btn-hero">FIND OUT MORE</a>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-5 col-lg-5 p-0 d-flex flex-column align-items-center">
            <img className="img-banner-fluid" src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21157&authkey=%21AEo1N1EWx2tuxXg&width=2440&height=2440" alt="banner 1" />
          </div>
        </div>
      </div>
      <div className="bannerServices2">
        <div className="row justify-content-center w-100 mx-0" id="Services">
          <div className="col-10 col-md-10 col-lg-10 text-center" style={{ backgroundColor: "white" }}>
            <div className="row w-100 m-0">
              <div className="col-12">
                <div className="title-bannerServices2 mt-5 mb-3">
                  <h6>Our Services</h6>
                </div>
              </div>
            </div>
            <div className="row w-100 m-0 justify-content-center">
              <div className="col-10">
                <div className="title2-bannerServices2">
                  <h1>We are the best solution for your team to Expertise, Creativity, Innovation</h1>
                </div>
              </div>
            </div>
            <div className="row w-100 m-0 justify-content-center">
              <div className="col-10">
                <div className="title-bannerServices2 my-3">
                  <h6 style={{ fontSize: "13pt" }}>We excel in fostering leadership skills, instructional design and business strategy. Blue Hawk strategies is your partner in flight.
                  </h6>
                </div>
              </div>
            </div>
            <div className="row w-100 my-4 mx-0 justify-content-center">
              <div className="card col-10 col-md-5 col-lg-3 p-0 m-3 media-icon">
                <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21158&authkey=%21AOzTvaiZ5aCvU34&width=768&height=1024" className="card-img-top w-100 img-cardServices" alt="card 1" />
                <div className="card-body">
                  <h5 className="card-title card-title-services text-start my-2">Five Stages of Team Development</h5>
                  <p className="card-text card-text-services text-start mb-4">Our transformative one day workshop promises to unlock the secret to successful teamwork!</p>
                  <a href="#fiveStates" className="btn-hero-services">LEARN MORE</a>
                </div>
              </div>
              <div className="card col-10 col-md-5 col-lg-3 p-0 m-3 media-icon">
                <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21113&authkey=%21AA-ckZDhS79Gm1U&width=2048&height=1536" className="card-img-top w-100 img-cardServices" alt="card 2" />
                <div className="card-body">
                  <h5 className="card-title card-title-services text-start my-2">Customized Training</h5>
                  <p className="card-text card-text-services text-start mb-4">Elevate your team's potential with our Needs-Based Customized Training!</p>
                  <a href="#customizedTraining" className="btn-hero-services">LEARN MORE</a>
                </div>
              </div>
              <div className="card col-10 col-md-5 col-lg-3 p-0 m-3 media-icon">
                <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21160&authkey=%21AEa0lRURMsmMSfA&width=9504&height=6336" className="card-img-top w-100 img-cardServices" alt="card 1" />
                <div className="card-body">
                  <h5 className="card-title card-title-services text-start my-2">Strategic Planning</h5>
                  <p className="card-text card-text-services text-start mb-4">Propel your organization to new heights with our Strategic Planning Workshop!</p>
                  <a href="#strategicPlaning" className="btn-hero-services">LEARN MORE</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row w-100 mx-0 justify-content-center" id="fiveStates">
        <div className="col-lg-6 p-0 d-none d-lg-block">
          <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21158&authkey=%21AOzTvaiZ5aCvU34&width=768&height=1024" className="img-fluid w-100" alt="background services 1" style={{ maxHeight: "500px", objectFit: "cover", minHeight: "500px" }} />
        </div>
        <div className="col-12 p-0 d-lg-none d-block" style={{ position: "relative" }}>
          <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21158&authkey=%21AOzTvaiZ5aCvU34&width=768&height=1024" className="img-fluid w-100 img-opacity-services" alt="background services 1" style={{ maxHeight: "500px", objectFit: "cover", minHeight: "650px" }} />
          <div className="position-absolute top-0 start-0 bottom-0 end-0 mx-5 text-center d-flex align-items-center flex-column justify-content-center py-3" style={{ zIndex: 1 }}>
            <h1 className="title2-bannerServices2-mobile">Five Stages of Team Development</h1>
            <h6 className="title-bannerServices2-mobile my-4" style={{ fontSize: "14pt" }}>Our transformative one day workshop promises to unlock the secret to successful teamwork! Discover the Five Stages of Team Development and watch your teams evolve into high-performing units. Learn how to navigate Forming, Storming, Norming, Performing, and Adjourning with expert guidance. Boost collaboration, communication, and productivity. Don't miss this chance to create harmonious and effective teams.</h6>
            <a href="https://calendly.com/terry-blue/30min" className="btn-right-slide-services-mobile">MAKE AN APPOINTMENT</a>
          </div>
          <div className="overlay" style={{ backgroundColor: "rgba(1, 59, 141, 0.67)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}></div>
        </div>
        <div className="col-10 col-md-10 col-lg-6 p-0 px-2 text-center hide-mobile d-flex align-items-center flex-column justify-content-center">
          <div className="title2-bannerServices2 my-3">
            <h1 className="">Five Stages of Team Development</h1>
          </div>
          <div className="title-bannerServices2 my-3 px-3">
            <h6 style={{ fontSize: "12pt", lineHeight: "2" }}>Our transformative one day workshop promises to unlock the secret to successful teamwork! Discover the Five Stages of Team Development and watch your teams evolve into high-performing units. Learn how to navigate Forming, Storming, Norming, Performing, and Adjourning with expert guidance. Boost collaboration, communication, and productivity. Don't miss this chance to create harmonious and effective teams.</h6>
          </div>
          <div className="my-4">
            <a href="https://calendly.com/terry-blue/30min" className="btn-right-slide-services">MAKE AN APPOINTMENT</a>
          </div>
        </div>
      </div>

      <div className="row w-100 mx-0 justify-content-center" id="customizedTraining">
        <div className="col-10 col-md-10 col-lg-6 p-0 px-2 text-center d-flex align-items-center flex-column justify-content-center hide-mobile" style={{ backgroundColor: "#013B8D" }}>
          <div className="title2-bannerServices2 my-3">
            <h1 className="">Customized Training</h1>
          </div>
          <div className="title-bannerServices2 my-3 px-3" style={{ color: "#fff" }}>
            <h6 style={{ color: "#fff", fontSize: "12pt", lineHeight: "2" }}>Elevate your team's potential with our Needs-Based Customized Training! Say goodbye to generic workshops and hello to tailored learning experiences. Our expert trainers will assess your team's unique strengths and challenges, crafting a training program that precisely addresses your needs. Whether it's leadership development, communication skills, or technical expertise, we've got you covered.</h6>
          </div>
          <div className="my-4">
            <a href="https://calendly.com/terry-blue/30min" className="btn-left-slide-services">MAKE AN APPOINTMENT</a>
          </div>
        </div>
        <div className="col-12 p-0 d-lg-none d-block" style={{ position: "relative" }}>
          <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21113&authkey=%21AA-ckZDhS79Gm1U&width=2048&height=1536" className="img-fluid w-100 img-opacity-services" alt="background services 1" style={{ maxHeight: "600px", objectFit: "cover", minHeight: "550px" }} />
          <div className="position-absolute top-0 start-0 bottom-0 end-0 mx-5 text-center d-flex align-items-center flex-column justify-content-center py-3" style={{ zIndex: 1 }}>
            <h1 className="title2-bannerServices2-mobile" style={{ color: "#013B8D" }}>Customized Training</h1>
            <h6 className="title-bannerServices2-mobile my-4" style={{ fontSize: "14pt", color: "#013B8D" }}>Elevate your team's potential with our Needs-Based Customized Training! Say goodbye to generic workshops and hello to tailored learning experiences. Our expert trainers will assess your team's unique strengths and challenges, crafting a training program that precisely addresses your needs. Whether it's leadership development, communication skills, or technical expertise, we've got you covered.</h6>
            <a href="https://calendly.com/terry-blue/30min" className="btn-right-slide-services">MAKE AN APPOINTMENT</a>
          </div>
          <div className="overlay" style={{ backgroundColor: "rgba(255, 255, 255, 0.78)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}></div>
        </div>
        <div className="col-lg-6 p-0 d-none d-lg-block">
          <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21113&authkey=%21AA-ckZDhS79Gm1U&width=2048&height=1536" className="img-fluid w-100" alt="background services 1" style={{ maxHeight: "600px", objectFit: "cover", minHeight: "550px" }} />
        </div>
      </div>

      <div className="row w-100 mx-0 justify-content-center" id="strategicPlaning">
        <div className="col-lg-6 p-0 d-none d-lg-block">
          <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21160&authkey=%21AEa0lRURMsmMSfA&width=9504&height=6336" className="img-fluid w-100" alt="background services 1" style={{ maxHeight: "600px", objectFit: "cover", minHeight: "550px" }} />
        </div>
        <div className="col-12 p-0 d-lg-none d-block" style={{ position: "relative" }}>
          <img src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21160&authkey=%21AEa0lRURMsmMSfA&width=9504&height=6336" className="img-fluid w-100 img-opacity-services" alt="background services 1" style={{ maxHeight: "650px", objectFit: "cover", minHeight: "600px" }} />
          <div className="position-absolute top-0 start-0 bottom-0 end-0 mx-5 text-center d-flex align-items-center flex-column justify-content-center py-3" style={{ zIndex: 1 }}>
            <h1 className="title2-bannerServices2-mobile m" style={{ color: "#013B8D" }}>Strategic Planning</h1>
            <h6 className="title-bannerServices2-mobile my-2" style={{ fontSize: "14pt", color: "#013B8D" }}>Propel your organization to new heights with our Strategic Planning Workshop! Unleash the power of foresight and create a roadmap for success. Our immersive workshop equips you with the tools to analyze market trends, set clear objectives, and craft a winning strategy. Guided by seasoned experts, you'll learn to navigate uncertainties and make informed decisions that drive growth. Whether you're a startup or an established firm, this workshop will empower you to shape your future.</h6>
            <a href="https://calendly.com/terry-blue/30min" className="btn-right-slide-services my-2">MAKE AN APPOINTMENT</a>
          </div>
          <div className="overlay" style={{ backgroundColor: "rgba(193, 168, 117, 0.7)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}></div>
        </div>
        <div className="col-10 col-md-10 col-lg-6 p-0 px-2 text-center hide-mobile d-flex align-items-center flex-column justify-content-center">
          <div className="title2-bannerServices2 my-3">
            <h1 className="">Strategic Planning</h1>
          </div>
          <div className="title-bannerServices2 my-3 px-3">
            <h6 style={{ fontSize: "12pt", lineHeight: "2" }}>Propel your organization to new heights with our Strategic Planning Workshop! Unleash the power of foresight and create a roadmap for success. Our immersive workshop equips you with the tools to analyze market trends, set clear objectives, and craft a winning strategy. Guided by seasoned experts, you'll learn to navigate uncertainties and make informed decisions that drive growth. Whether you're a startup or an established firm, this workshop will empower you to shape your future.</h6>
          </div>
          <div className="my-4">
            <a href="https://calendly.com/terry-blue/30min" className="btn-right-slide-services">MAKE AN APPOINTMENT</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
