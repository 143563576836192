import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import "../Styles/Navbar.css";

const NavigationBar = () => {
  return (
    <Navbar className="navbar-background fixed-top" expand="md">
      <Container className='w-100'>
        <Navbar.Brand href="/">
          <img className="navbar-brand-logo" src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21146&authkey=%21AN9RgCjN5v30yvA&width=7673&height=1687" alt="Brand Logo" />
        </Navbar.Brand>
        <Navbar.Toggle className='navbar-toggle' aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/Services">Services</Nav.Link>
            <Nav.Link href="/AboutUs">About Us</Nav.Link>
            <Nav.Link href="/Connect">Connect</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
