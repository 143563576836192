import React from "react";
import "../Styles/Home.css";
import videoFirstSideCard from "../assets/Homescreenvideo.mp4";

const Home = () => {
  return (
    <div className="container-fluid p-0 mt-5">
      <div className="hero banner1 position-relative">
        <div className="my-4" style={{ zIndex: 1 }}>
          <h2 className="title-banner1">Welcome</h2>
        </div>
        <div className="mx-3 my-4 p-0 card-banner1" style={{ maxWidth: "940px", backgroundColor: "#013B8D", zIndex: 2 }}>
          <div className="row g-0 justify-content-center">
            <div className="col-md-6 col-lg-6 d-flex flex-column aligns-items-center">
              <video className="w-100 m-0 p-0 round-start video-style" style={{ minHeight: "456px" }} loop autoPlay muted>
                <source src={videoFirstSideCard} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-md-6 col-lg-6 secondSideCard d-flex align-items-center justify-content-center">
              <div className="card-body">
                <div className="title-box">
                  <h5 className="title-hero m-0">Helping your team<br />fly higher than the <br /><span className="special-title">COMPETITION.</span></h5>
                </div>
                <div className="my-5">
                  <h3 className="subtitleBanner1">Expertise, Creativity, Innovation</h3>
                </div>
                <div>
                  <a href="/Services" className="btn-hero">FIND OUT MORE</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay-banner"></div>
      </div>
      <div className="hero banner2">
        <div className="title-banner2 my-5 d-md-block d-none">
          <h2 className="MainTitle-banner2">Who We Are</h2>
        </div>
        <div className="row w-100 m-0 justify-content-center">
          <div className="col-12 d-md-none" style={{ position: "relative" }}>
            <img className="img-fluid px-3 img-opacity imgBanner2" src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21114&authkey=%21ALaCkMbzz_1ztNY&width=1536&height=2048" alt="who we are" />
            <h2 className="MainTitle-banner2 d-flex justify-content-center align-items-center position-absolute top-0 start-0 bottom-0 end-0 m-auto">Who We Are</h2>
          </div>
          <div className="col-11 col-md-5 col-lg-5 d-flex align-items-center justify-content-center flex-column">
            <div className="text-banner2 my-3 my-lg-5 h5 text-md-center text-lg-start" style={{ textAlign: "left" }}>
              We are experts in crafting personalized, dynamic learning journeys and strategies aimed at enhancing team performance.<br /> <br />
              From leadership cultivation and curriculum refinement to instructional design and strategic ideation, Blue Hawk Strategies will elevate your team to excellence.<br /><br />
              Hablamos Español
            </div>
            <div className="my-3">
              <a href="/AboutUs" className="btn-banner2">LEARN MORE</a>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-5 d-none d-md-flex align-items-center justify-content-center">
            <img className="img-fluid img-banner-2" src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21114&authkey=%21ALaCkMbzz_1ztNY&width=1536&height=2048" alt="second banner" />
          </div>
        </div>
      </div>
      <div className="hero banner3">
        <div className="my-3">
          <h2 className="MainTitle-banner3">What We Do</h2>
        </div>
        <div className="row m-0 justify-content-center">
          <div className="col-10 col-md-6 col-lg-5 p-3 text-center text-lg-start" style={{ fontFamily: "Montserrat, sans-serif" }}>
            <p className="title-banner3">Tailored Made Learning: </p>
            <p className="body-banner3">Experience the power of tailored corporate training that revolutionizes the way your team learns, collaborates, and thrives. Join hands with us and embark on a journey of transformation that propels your company toward greatness.</p>
            <p className="title-banner3">Team Development:</p>
            <p className="body-banner3">Blue Hawk Strategies' signature  Leading three-hour workshop  Leading Through the Storm has been developed with the primary objective of enabling organizational leaders to effectively navigate the transition from the Storming stage to the Norming stage and beyond. This immersive and dynamic workshop encompasses comprehensive theoretical insights and a set of meticulously tailored scenarios. These components collectively serve as invaluable tools, empowering leaders to adeptly navigate and successfully manage complex change processes within their teams.</p>
            <p className="title-banner3">Guiding Success Through <br />Expert Strategic <br />Planning Coaching:</p>
            <p className="body-banner3">
              Results-Oriented Approach: Whether you're aiming to expand market share, enhance operational efficiency, or redefine your industry, our coaching empowers you to achieve measurable and sustainable success.<br /><br />
              Tap into the wisdom of strategic experts who've navigated diverse landscapes. Our coaches bring hands-on experience and fresh perspectives to every coaching session, sparking innovative thinking.
            </p>
          </div>
          <div className="col-md-12 col-lg-5 d-flex align-items-center justify-content-center flex-column">
            <div className="d-none d-lg-block">
              <img className="img-banner3 img-fluid" src="https://onedrive.live.com/embed?resid=6F96C7D49D9BDA1A%21117&authkey=%21ADD0T3IRRqaPVSo&width=1536&height=1907" alt="banner 3" />
            </div>
            <div className="my-4">
              <a href="/Services" className="btn-banner3 w-100">LEARN MORE</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
